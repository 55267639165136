import Slider from "react-slick";

import Image1 from '../assets/ask-tolik.png';
import Image2 from '../assets/parabolic.png';
import Image3 from '../assets/dogeum.png';
import Image4 from '../assets/shell-app.png';

const Customers=()=>{

    const settings = {
        className: "center",
        dots: false,
        infinite: true,
        centerMode: true,
        centerPadding: "60px",
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2000,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 1280,
                settings:{
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                }
            },
            {
                breakpoint: 991,
                settings:{
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                  }
            }
        ]
    }

    return(
        <section id="customers-section">
            <div className="container">
                <div className="customers-innerdiv">
                    <div className="customers-heading">
                        <h2 style={{color: '#f3b113'}}>OUR CUSTOMERS</h2>
                    </div>


                    <div>
                        <Slider className="sldier-carousel" {...settings}>
                            <div class="text-img-slider">
                                <img src={Image1} alt='image1' loading="lazy" />
                            </div>
                            <div class="text-img-slider">
                                <img src={Image2} alt='image2' loading="lazy" />
                            </div>
                            <div class="text-img-slider">
                                <img src={Image3} alt='image3' loading="lazy" />
                            </div>
                            <div class="text-img-slider">
                                <img src={Image4} alt='image4' loading="lazy" />
                            </div>
                        </Slider>
                    </div>

                    <div className="customer-text">
                        <p>
                            The company is honored to be chosen as a long-term partner, 
                            creating world-class games for WB, Glu Mobile, Azerion, and 
                            other major publishers and game development companies. Our 
                            partners say that Moonmana's professional engineers cope with 
                            challenging, complex tasks efficiently and on time.
                        </p>
                    </div>

                </div>
            </div>
        </section>
    );
}

export default Customers;