import image1 from '../assets/up-work.webp';
import image2 from '../assets/doulabzlogo.png';
import image3 from '../assets/fiver.png';

const Works=()=>{
    return(
        <section id="works-section">
            <div className="container">
                <div className="inner-work-section">
                    <div className="work-heading">
                        <h2 style={{color: '#f3b113'}}>FIND MORE ABOUT DUOLABZ</h2>
                    </div>

                    <div className="lower-container">
                        <div className="inline-img">
                            <p>Our Upwork Profile</p>
                            <a href='https://www.upwork.com/freelancers/~01dd59e027c6db6983' target="_blank" rel="noopener noreferrer"><img src={image1} alt='upwork'/></a>
                        </div>
                        <div className="inline-img">
                            <p>Our Pakistan office</p>
                            <a href='https://duolabz.com/' target="_blank" rel="noopener noreferrer"><img src={image2} alt='good films'/></a>
                        </div>
                        <div className="inline-img">
                            <p>Our Fiver Profile</p>
                            <a href='https://www.fiverr.com/regex_soft' target="_blank" rel="noopener noreferrer"><img src={image3} alt='artstation'/></a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Works;