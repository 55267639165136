import img1 from '../assets/santafloki.png';
import img2 from '../assets/spookyhaloween.png';
import img3 from '../assets/tomagason.png';
import img4 from '../assets/spookyhalloween-blockchain.png';

import img5 from '../assets/gym.png';
import img6 from '../assets/scanner.png';
import img7 from '../assets/vpn.png';
import img8 from '../assets/wonderpetz.png';

import img9 from '../assets/dogeumbg.png';
import img10 from '../assets/egonverse.png';
import img11 from '../assets/spookyhalloween-website.png';
import img12 from '../assets/dxb.png';

/*
import img13 from '../assets/1.png';
import img14 from '../assets/2.png';
import img15 from '../assets/3.png';
import img16 from '../assets/4.png';
*/

import Slider from "react-slick";
import React, { useState } from 'react';

const Services = () => {

    const [currentTab, setCurrentTab] = useState('1');
    const tabs = [
        {
            id: 1,
            tabTitle: 'BlockChain development & NFT design',
            content: 'Unbolt our digital services that are trusted by many. We have benefited several clients with our expertise and always feel excited to work with the new ones. We are here to program decentralized applications (DApps) for your business purposes. We can provide services in creating Launchpads, ICOs, smart contracts, Utility applications etc. You name it and we make it.',

            data: [{
                photo: img1, URL: "https://dapp.santafloki.com/", name: "Decentralize Application (dApp)", Desc: "SantaFloki is a decentralized application (dApp) built on the Binance blockchain. It is designed to provide users with all of the SantaFloki services in a single space. The dApp ecosystem allows users to stake and mint NFTs as well as p2e game. With no centralized authority controlling the platform, SantaFloki ensures a transparent and secure exchange experience for its users.",
            },
            {
                photo: img2, URL: "https://spookyhalloweenfloki.com/minting/", name: "NFT Minting", Desc: "SpookyHalloweenFloki is a non-fungible token (NFT) minting page built on the Binance Smart Chain using React.js . It offers users the opportunity to mint their own unique NFTs, which can be bought, sold, or traded on various NFT marketplaces on the Binance Smart Chain.",

            }, 
            {
                photo: img3, URL: "https://tamagosan-frontend.vercel.app/", name: "NFT 2.0", Desc: "Tamagosan is a customised NFT builder that allows users to create their own unique NFTs using a simple and user-friendly interface. The platform offers a variety of customisation options, including the ability to add custom traits to the NFTs. Users can create their own from scratch. Once the NFT is created,  app allows to customise the NFT an unlimited number of time giving you full control on how you want you tamagosan to look.",

            }, 
            {
                photo: img4, URL: "https://spookyhalloweenfloki.com/staking/", name: "NFT Staking", Desc: "SpookyHalloweenFloki is an NFT staking platform that allows users to earn rewards by staking their NFTs. Users can stake their NFTs for a certain period of time, during which they earn rewards in the form of SpookyHalloweenFloki tokens (SHF). The longer the staking period, the higher the rewards. SHF tokens has various utilities. The staking process is simple and straightforward, and users can track their staking rewards and NFTs easily on the platform. With its attractive rewards and easy-to-use interface, SpookyHalloweenFloki’s NFT staking platform is a popular choice for NFT holders looking to earn passive income.",

            }
        ]
        },
        {
            id: 3,
            tabTitle: 'App development & design',
            content: 'Our philosophy is to help people create what they dream of. We have great IT enthusiasts in our team to support the Development of your project. We help you generate and regulate your revenues using our assistance and expertise.',

            data: [{
                photo: img5,  name: "Gym Fitness Application", Desc: "Our team developed a comprehensive gym fitness application that helps users achieve their fitness goals. The app features custom workout plans, fitness tracking, and nutrition advice. Our team also implemented gamification features to make the app fun and engaging for users.",
            },
            {
                photo: img6,  name: "Scanner-Scan", Desc: "Scanner-Scan is a simple, yet powerful app that allows users to quickly scan and save documents using their smartphone camera. Our team designed an intuitive interface and implemented cutting-edge OCR technology to ensure accurate scanning and easy document management.",

            }, 
            {
                photo: img7,  name: "VPN for Pubg", Desc: "Our team created a VPN for PUBG, a popular mobile game. The app provides users with a secure and stable connection while playing the game, which can help reduce lag and improve overall gameplay. We implemented advanced encryption technology to ensure the highest level of security for our users.",

            }, 
            {
                photo: img8,  name: "Wonderpetz", Desc: "Wonderpetz is an app designed for pet owners. It provides a platform for connecting with other pet owners, sharing tips and advice, and finding pet-friendly events and activities in your area. Our team created a user-friendly interface and integrated social media features to make it easy for users to connect with other pet owners.",

            }
        ]
        },
        {
            id: 4,
            tabTitle: 'Website development & design',
            content: 'The Technical world aspires people who love to grow and are ambitious. We have gathered a team of such ambitious individuals. Creating websites that leave a perfect first impression of our client’s business is always our focus. Our best services include the web development and professional UX for web advertisers. Our work will certainly help your business grow at an unimaginable pace.',

            data: [{
                photo: img9, URL: "https://dogeum.io/", name: "Dogeum", Desc: "Dogeum is a cryptocurrency website that allows users to buy, sell, and trade Dogecoin. Our team created a sleek and modern interface, and implemented advanced security features to ensure a safe and secure user experience.",
            },
            
            {
                photo: img10, URL: "https://egonverse.com/", name: "Egonverse", Desc: "Our expert developers create engaging and interactive virtual reality experiences that allow users to explore and interact with 3D environments.",

            },
            {
                photo: img11, URL: "https://spookyhalloweenfloki.com/", name: "Spookyhalloween Floki", Desc: "Our team specializes in creating eerie and spooky websites and apps that are perfect for Halloween-themed events and businesses.",

            }, 
            {
                photo: img12, URL: "http://dxbsquad.io/", name: "Dxbsquad", Desc: "Dxbsquad.io is a community website for developers in Dubai. Our team created a user-friendly platform that allows developers to connect with each other, share ideas, and collaborate on projects. We also implemented social media features to make it easy for users to share content and connect with others.",

            }
        ]
        }
    ];

    const handleTabClick = (e) => {
        setCurrentTab(e.target.id);
    }

    const settings = {
        className: "center",
        dots: false,
        infinite: true,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 4000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    arrows: false,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    arrows: true,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    arrows: true,
                }
            }
        ]
    }

    return (
        <section id="sevices-container">

            <div className="container">
                <div className="about-inner-div">
                    <div className="services-heading">
                        <h2 style={{ color: '#f3b113' }}>OUR SERVICES</h2>
                    </div>



                    <div className='tabs'>
                        {tabs.map((tab, i) =>
                            <button key={i} id={tab.id} disabled={currentTab === `${tab.id}`} onClick={(handleTabClick)}>{tab.tabTitle}</button>
                        )}
                    </div>
                    <div className='content'>
                        {tabs.map((tab, i) =>
                            <div key={i}>
                                {currentTab === `${tab.id}` &&
                                    <div>
                                        <p className='title'>
                                            {tab.title}
                                        </p>

                                        <div className='tabs-images'>
                                            <div>
                                                <Slider className="sldier-carousel" {...settings}>
                                                    {tab.data.map((item) => {
                                                        return (
                                                            <div>
                                                                <a href={item.URL} target="_blank" rel="noopener noreferrer"><p className='heading'>{item.name}</p></a>
                                                                <img src={item.photo} alt='development' loading="lazy" />
                                                                <p>{item.Desc}</p>
                                                            </div>
                                                        )
                                                    })}
                                                </Slider>
                                            </div>


                                        </div>
                                    </div>}
                            </div>
                        )}
                    </div>

                </div>
            </div>
        </section>
    );
}

export default Services;