/*import video from "../assets/mainbg-video.mp4";*/
/*import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
*/

const Header =()=>{

    return(
        <header id="header-container">

            {/*<video loop autoPlay muted src={video} />*/}
            
            <div className="container">
                <div className="text-container">
                    <div className="inner-container">
                        <h2>EFFECTIVE <span>GAMES CO-DEVELOPMENT</span></h2><br />
                        <h3>MAKES FOR BETTER GAMES</h3>
                    </div>

                    <a href="#contact" rel="noopener noreferrer">
                        <div className="banner-btn">
                            <p> LET'S TALK</p>
                        </div>
                    </a>
                </div>
            </div>

        </header>
    );
}

export default Header;