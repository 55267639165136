import Slider from "react-slick";

import Image1 from '../assets/client1.png';
import Image2 from '../assets/client2.png';
import Image3 from '../assets/client3.png';
import Image4 from '../assets/client4.png';
import Image5 from '../assets/client5.png';

const Clients=()=>{


    const settings = {
        className: "center",
        dots: false,
        infinite: true,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 4000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        responsive: [
            {
                breakpoint: 1280,
                settings:{
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    arrows: true,
                }
            },
            {
                breakpoint: 991,
                settings:{
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    arrows: true,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    arrows: true,
                  }
            }
        ]
    }


    return(
        <section id="client-section">
            <div className="container">
                <div className="inner-client-section">
                    <div className="client-heading">
                        <h2 style={{color: '#f3b113'}}>OUR CLIENTS SAY</h2>
                    </div>

                    <div>
                        <Slider className="sldier-carousel" {...settings}>
                            <div class="client-slider">
                                <img src={Image1} alt='image1' loading="lazy" />
                                <p>
                                    “Duolabz went far beyond my expectation, and helped to amend issues within my project that i did not know of,
                                    he also did a lot of extra work which i appreciate very, much will definitely 
                                    recommend and work with them again”
                                </p>
                                <h3>Arturo Mendez</h3>
                                <h5>Snaky.io</h5>
                            </div>
                            <div class="client-slider">
                                <img src={Image2} alt='image2' loading="lazy" />
                                <p>
                                    “Great Team to work with. Highly skilled, attention to detail and constant communication. 
                                    Versatile team that can do any type of game.”
                                </p>
                                <h3>Stav Zilbershtein</h3>
                                <h5>SellMyApp</h5>
                            </div>
                            <div class="client-slider">
                                <img src={Image3} alt='image3' loading="lazy" />
                                <p>
                                    “Duolabz Is a pleasure to work with. They have been more than helpful and there 
                                    quality of work has been top notch.There contact person was always on skype 
                                    and talked to me without hesitation when i had questions. He got the job done 
                                    fast and will definitely hire him again.I got to see there art, its amazing….
                                    I am going to hire them for all my art projects in the near future. 
                                    😀Thanks again Duolabz”
                                </p>
                                <h3>Gary Mass</h3>
                                <h5>Needreceipt.com</h5>
                            </div>
                            <div class="client-slider">
                                <img src={Image4} alt='image4' loading="lazy" />
                                <p>
                                    “Worked with Duolabz on application development, as a newer person to the industry. 
                                    They were helpful, insightful, came up with brilliant ideas and improvements. 
                                    I would 100% recommend them. We are very pleased, and will be doing more projects in the future. 
                                    Good, honest, fast, pleasant and dependable. Awesome job!”
                                </p>
                                <h3>Nitha</h3>
                                <h5>Damita Victoria</h5>
                            </div>
                            <div class="client-slider">
                                <img src={Image5} alt='image5' loading="lazy" />
                                <p>
                                    “Great Upwork service company. Great quality, accountable for required and satisfactory work. 
                                    Looking forward to complete more of the projects on hand.”
                                </p>
                                <h3>Anatolye Eidelman</h3>
                                <h5>ASKTOLIK.com</h5>
                            </div>
                        </Slider>
                    </div>

                </div>
            </div>
        </section>
    );
}

export default Clients;