import React from 'react';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import Logo from '../assets/banner-logo.png';

const Whatsapp =()=> {

  return (
      <FloatingWhatsApp
      phoneNumber="+971551561053"
      accountName="Duolabz"
      avatar={Logo}
        />
  )
}

export default Whatsapp;