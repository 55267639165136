/*import blurimage from '../assets/blur-effect.png';*/

const Ourgames =()=>{
    return(
        <section id="Our-games">

            {/*
            <div className='blur-effect2'>
                <img src={blurimage} alt='image'/>
            </div>
            */}

            <div className="container">
                <div className="ourgames-innerdiv">
                    <div className="ourgames-heading">
                        <h2 style={{color: '#f3b113'}}>OUR TOP HITS</h2>
                    </div>

{/*
                    <div className="ourgames-text">
                        <p>
                            We are devoted to every project we make, we go the extra mile to create really great games - <br />
                            with astonishing art, smooth gameplay and unique game mechanics.
                        </p>
                    </div>
*/}
                </div>
            </div>
        </section>
    );
}

export default Ourgames;