import CountUp from 'react-countup';

const Counter=()=>{
    return(
        <section id="counter-section">
            <div className="container">
                <CountUp start={0} end={14} delay={0} enableScrollSpy>
                {({ countUpRef }) => (
                    <div className='counter-section'>
                    <span ref={countUpRef} />
                    <h2>INTACT IN INDUSTRY</h2>
                    </div>
                )}
                </CountUp>

                <CountUp start={0} end={5000} delay={0} enableScrollSpy>
                {({ countUpRef }) => (
                    <div className='counter-section'>
                    <span ref={countUpRef} />
                    <h6>+</h6>
                    <h2>DELIEVERED AND GROWING</h2>
                    </div>
                )}
                </CountUp>

                <CountUp start={0} end={7520} delay={0} enableScrollSpy>
                {({ countUpRef }) => (
                    <div className='counter-section'>
                    <span ref={countUpRef} />
                    <h2>GAMES WE’VE WORKED ON</h2>
                    </div>
                )}
                </CountUp>

                <CountUp start={0} end={45} delay={0} enableScrollSpy>
                {({ countUpRef }) => (
                    <div className='counter-section'>
                    <span ref={countUpRef} />
                    <h2>PARTNERS PICKED US</h2>
                    </div>
                )}
                </CountUp>
            </div>
        </section>
    );
}

export default Counter;