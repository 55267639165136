import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
/*import Logo from '../assets/banner-logo.png';*/
import Logo from '../assets/banner-logo.png';
import React, { useEffect, useState } from 'react';


const Navigation =()=>{

  const [offset] = useState(0);
  useEffect(() => {
      const scrollFunction = () => {
          if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
              document.getElementById("navbar-logo").style.cssText = "width: 20% !important; position: relative; top: -25px; left: 150px;";
          } else {
              document.getElementById("navbar-logo").style.cssText = "width: 30%;";
          }

          if (window.innerWidth <= 1200) {
            document.getElementById("navbar-logo").style.cssText = "position: relative; top: -38px; left: 100px;";
          } else {
            document.getElementById("navbar-logo").style.display ='block';
          }
      };
      window.removeEventListener('scroll', scrollFunction);
      window.addEventListener('scroll', scrollFunction, { passive: true });
      return () => window.removeEventListener('scroll', scrollFunction);
  }, []);

  console.log(offset); 

    return(
        <div className="body">
        <div className='container'>
            <Navbar id="navbarBg" collapseOnSelect expand="lg" variant="dark" fixed="top" className="navbarBg navbar-fixed-top">
            <Container className="logo-className">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto links">
                        <Nav.Link className="nav-items text-nowrap active left" href="#header-container">Home</Nav.Link>
                        <Nav.Link className="nav-items text-nowrap left" href="#about-section">About Us</Nav.Link>
                        <Nav.Link className="nav-items text-nowrap left" href="#sevices-container">Services</Nav.Link>
                        <Nav.Link className="text-nowrap mobile"><img id='navbar-logo' src={Logo} alt='logo' loading="lazy" /></Nav.Link>
                        <Nav.Link className="nav-items text-nowrap right" href="#Our-games">Our Projects</Nav.Link>
                        <Nav.Link className="nav-items text-nowrap right" href="#works-section">Duolabz Pk</Nav.Link>
                        <Nav.Link className="nav-items text-nowrap right" href="#contact">Contact Us</Nav.Link>
                </Nav>
                </Navbar.Collapse>
            </Container>
            </Navbar>

        
        </div>
        </div>
    );
}

export default Navigation;