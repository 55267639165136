import Slider from "react-slick";

import img1 from '../assets/AppStore.webp';
import img2 from '../assets/Google_Play.webp';

import img3 from '../assets/website-btn.png';
import img4 from '../assets/demo.png';

import img5 from '../assets/logo.png';


function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "transparent" }}
        onClick={onClick}
      />
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "transparent" }}
        onClick={onClick}
      />
    );
  }



const Games=()=>{

    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 3000,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,

        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: false,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 2,
                dots: false,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
              }
            }
          ]
      }

    return(
        <section id="games-section1">

                    <div>
                        <Slider style={{ width: "100%" }} className="sldier-carousel" {...settings}>

                        <div class="full-img-slider5">
                            <div className='container'>
                                    <div class='slider-logo'>
                                        <img src={img5} alt="logo" />
                                    </div>
                                <div className='Two-section-container5'>
                                    <div className='Two-section-text5'>
                                        <div className='Two-sectiontext-heading5'>
                                            <h2>Xcrusaders</h2>
                                        </div>
                                        <div className='Two-sectiontext-para5'>
                                            <p>
                                                Real-time strategy, p2e <br/>
                                                Beta Launched: 2023<br/><br/>

                                                Xcrusaders is a real time strategy kingdom maker game on XRP Ledger.
                                                Play Within A Massive World With 8 Regions To Explore, travel between different Biomes & Settle Your Kingdom.
                                                Divide & Conquer With Friends, Grow Your Kingdom & Become King.
                                                Trade With Other Players, Buy & Lease Land & See Your Kingdom Become A Economic Powerhouse.
                                            </p>
                                            <br />
                                            <div className='app-storebtn inline5'>
                                                <a href="https://xcrusaders.com/" target="_blank" rel="noopener noreferrer"><img src={img3} alt='' /></a>
                                            </div>

                                            <div className='google-btn inline5'>
                                                <a href="https://betaxcrusaders.com/" target="_blank" rel="noopener noreferrer"><img src={img4} alt='' /></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{clear: 'both'}}></div>
                                </div>
                            </div>
                        </div>

                        <div class="full-img-slider4">
                            <div className='container'>
                                <div className='Two-section-container'>
                                    <div className='Two-section-text'>
                                        <div className='Two-sectiontext-heading'>
                                            <h2>Santa Floki Rush</h2>
                                        </div>
                                        <div className='Two-sectiontext-para'>
                                            <p>
                                                Genre: 2D platform, p2E <br/>
                                                Launched: 2022<br/><br/>

                                                Santa Floki is a p2E platform game that is unique in its graphics and game environment.
                                                 A project developed in unity game engine with a very smooth and attractive user experience. 
                                                 As the first Floki free to play game, santa floki Rush creates a fun and exciting environment
                                                for the player to collect coins and defeat challenging bosses! Available for android and iOS 
                                                platform as well as web3 where player earn reward (HOHOHO) as he clear the challenging level 
                                                and defeat bosses.
                                            </p>
                                            <br />
                                            <div className='app-storebtn inline'>
                                                <a href="https://apps.apple.com/us/app/santa-floki-rush/id1614053788" target="_blank" rel="noopener noreferrer"><img src={img1} alt='app store' /></a>
                                            </div>

                                            <div className='google-btn inline'>
                                                <a href="https://play.google.com/store/apps/details?id=com.santa.floki.rush&hl=en&gl=US" target="_blank" rel="noopener noreferrer"><img src={img2} alt='google playstore' /></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{clear: 'both'}}></div>
                                </div>
                            </div>
                        </div>


                            <div class="full-img-slider3">
                                <div className='container'>
                                <div className='text-container'>
                                    <div className='text'>
                                        <div className='text-heading'>
                                            <h2>Dogeum</h2>
                                        </div>
                                        <div className='text-para'>
                                            <p>
                                                Genre: Action-adventure,2D platform, p2E <br/>
                                                Launched: 2021<br/><br/>

                                                Dogeum is a next-generation blockchain play2earn game that will have metaverse powers 
                                                in its upcoming updates. Dogeum is based on the BinanceChain and uses the Dogecoin 
                                                cryptocurrency as its in-game currency. Dogeum will allow players to earn Dogecoins 
                                                by playing games and completing tasks.
                                            </p>
                                            <br />
                                            <div className='text-btn'>
                                                <a href="https://dogeum.io/p2e/" target="_blank" rel="noopener noreferrer"><p>PLAY</p></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{clear: 'both'}}></div>
                                </div>
                            </div>
                            </div>

                            <div class="full-img-slider2">
                                <div className='container'>
                                <div className='Two-section-container'>
                                    <div className='Two-section-text'>
                                        <div className='Two-sectiontext-heading'>
                                            <h2>Galaxy Attack: Alien Shooting</h2>
                                        </div>
                                        <div className='Two-sectiontext-para'>
                                            <p>
                                                Genre: Action Shooter <br/>
                                                Launched: 2016<br/><br/>

                                                Earth's last hope is in your hands, take control of the lone spaceship and protect 
                                                Earth from alien swarms attack. Your goal will be quite challenging as you will 
                                                have to save the Universe from its evil enemies. In this space shooting game, 
                                                you will be facing an increasingly large number of enemies in hazardous environments. 
                                                As the game progresses, you will earn the right to upgrade your spacecraft to unleash 
                                                its full lethal capacity.
                                            </p>
                                            <br />
                                            <div className='app-storebtn inline'>
                                                <a href="https://apps.apple.com/us/app/galaxy-attack-alien-shooter/id1176011642" target="_blank" rel="noopener noreferrer"><img src={img1} alt='app store' /></a>
                                            </div>

                                            <div className='google-btn inline'>
                                                <a href="https://play.google.com/store/apps/details?id=com.alien.shooter.galaxy.attack" target="_blank" rel="noopener noreferrer"><img src={img2} alt='google playstore' /></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{clear: 'both'}}></div>
                                </div>
                            </div>
                            </div>

                            <div class="full-img-slider1">
                            <div className='container'>
                                    <div className='text-container'>
                                        <div className='text'>
                                            <div className='text-heading'>
                                                <h2>FishingGo.io</h2>
                                            </div>
                                            <div className='text-para'>
                                                <p>
                                                    Genre: Real-time strategy, p2E <br/>
                                                    Launched: <br/><br/>

                                                    Extremely realistic simulator powered by Houdini and Unreal Engine. 
                                                    The player has to work in an apartment in modern design, solving different 
                                                    tasks such as cooking pizzas via the real-life recipes, meanwhile rethinking 
                                                    the life’s transience, the futility of pleasure, and the inevitability of death.
                                                </p>
                                                <br />
                                                <div className='text-btn'>
                                                    <a target="_blank" rel="noopener noreferrer"><p>PLAY</p></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{clear: 'both'}}></div>
                                    </div>
                                </div>
                            </div>
                            
                        </Slider>
                    </div>

{/*
            <div className="bgimg1">
                <div className='container'>
                    <div className='text-container'>
                        <div className='text'>
                            <div className='text-heading'>
                                <h2>HADDIE’S PIZZERIA</h2>
                            </div>
                            <div className='text-para'>
                                <p>
                                    Genre: Simulator <br/>
                                    Technologies used: Unreal Engine 4 <br/>
                                    Platforms: PC (Steam), PlayStation, XBOX, Nintendo <br/><br/>

                                    Extremely realistic simulator powered by Houdini and Unreal Engine. 
                                    The player has to work in an apartment in modern design, solving different 
                                    tasks such as cooking pizzas via the real-life recipes, meanwhile rethinking 
                                    the life’s transience, the futility of pleasure, and the inevitability of death.
                                </p>
                                <br />
                                <div className='text-btn'>
                                    <p>LEARN MORE</p>
                                </div>
                            </div>
                        </div>
                        <div style={{clear: 'both'}}></div>
                    </div>
                </div>
            </div>
*/}
        </section>
    );
}

export default Games;