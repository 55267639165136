import './App.css';
import './Responsive.css';

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import 'bootstrap/dist/css/bootstrap.min.css';


import Navigation from './components/Navigation';
import Header from './components/Header';
import About from './components/About';
import Services from './components/Services';
import Ourgames from './components/OurGames';
import Games from './components/Games';
/*import Two from './components/Two';
import Three from './components/Three';
import Four from './components/Four';*/
import Customers from './components/Customers';
import Counter from './components/Counter';
import Clients from './components/Clients';
import Works from './components/Works';
import Contact from './components/Contactus';
/*import Footer from './components/Footer';*/
import Credits from './components/Credits';
import Whatsapp from './components/Whatsapp';

function App() {
  return (
    <div className="body">
       <Navigation />
       <Header />
       <About />
       <Services />
       <Ourgames />
       <Games />
       <Customers />
       <Counter />
       <Clients />
       <Works />
       <Contact />
       <Credits />
       <Whatsapp />
    </div>
  );
}

export default App;
