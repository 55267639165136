import aboutImage from "../assets/about_image.png";

const About=()=>{
    return(
        <section id="about-section">
            <div className="container">
                <div className="about-innerdiv">
                    {/*
                    <div className="about-heading">
                        <h2>WHO <span style={{color: 'red'}}>WE</span> ARE?</h2>
                    </div>
    */}
                    <div className="about-container">
                        <div className="left-div">
                            <div className="text-left-container">
                                <div className="about-heading">
                                    <h2 style={{color: '#f3b113'}}>WHO WE ARE?</h2>
                                </div>
                                <p>
                                    Duolabz is a platform for visions and thoughts. 
                                    A flawless performance and an amazing online platform to back 
                                    that performance are the necessities of today's world. We are here 
                                    to create that platform for you.  We gather your concepts even if 
                                    they are scattered and covert them into actual form. Elevating 
                                    your vision is our mission. We are a professionally trained strong team 
                                    of Designers, managers, developers, advisors and HRs.
                                </p>
                            </div>
                        </div>
                        <div className="right-div">
                            <div className="image-container">
                                <img src={aboutImage} alt='about-img'/>
                            </div>
                        </div>
                        <div style={{clear: 'both'}}></div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;