

const Credits=()=>{
    
    return(
        <section id="credits-section">
            <div className="container">
                <div className="credit-text">
                    <p>© 2022 Duolabz. All Rights Reserved. <a href="https://duolabz.com/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a></p>
                </div>
            </div>
        </section>
    );
}

export default Credits;
